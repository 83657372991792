:root{
    --violet: #774DEF;
    --indigo: #3E318F;
    --lightSlate: #DDDEDE;
    --fontColor: #414042;
    --bgColor: #FFFFFF;
    --lightGray: #F6F6F6;
}

@font-face {
    font-family: Grtsk-QuidelOrtho-V;
    src: url("../assets/fonts/GrtskQuidelOrthoV.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
}

body{
    font-family: "Grtsk-QuidelOrtho-V";
}

#font-fmly-russian-only {
    font-family: Noto Sans !important;
}

body, p, h1, h2, h3, h4, h5, h6{
    margin: 0;
}

mark{
    background-color: var(--bgColor);
    font-weight: 600;
    color: inherit;
}

.app-cnt{
    background: var(--bgColor);
}

.qo-icon{
    color: var(--violet);
    opacity: 0.8;
}

.qo-icon-indigo{
    color: var(--indigo);
    opacity: 0.8;
}

.fs-20{
    font-size: 20px;
}

.fs-18{
    font-size: 18px;
}

.fs-16{
    font-size: 16px;
}

.fs-12{
    font-size: 16px;
}

/* AEM Header & Footer */
.headerPlaceholder{
    display: flex;
    justify-content: center;
    height: 100px;
    align-items: center;
}

.sui-layout-body{
    background: var(--bgColor) !important;
}

/* Top layout  */

.sui-layout-header{
    width: 100%;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    padding: 0 24px;
    border-bottom: none !important;
}

.sui-layout-header__inner{
    width: 100%;
}

.qo-search-cnt{
    margin: 100px 0 80px 0;
}

.qo-search-header-cnt{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 120px;
}

.qo-search-header-txt{
    color: var(--indigo);
    font-style: normal;
    font-weight: 600;
    font-size: 49px;
    line-height: 64px;
    margin-right: 100px;
    text-transform: capitalize;
}

.qo-search-box-cnt{
    flex-basis: 100%;
    position: relative;
}

.qo-search-result-txt{
    color: var(--indigo);
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
}


/* Searchbox  */

.sui-search-box__text-input::placeholder { 
    
    color: var(--indigo) !important;
    opacity: 1; 
    font-size: unset !important;
    line-height: unset !important;
    text-transform: capitalize;
}

.sui-search-box__text-input:focus{
    box-shadow: none !important;
}


.qo-searchBox .sui-search-box__submit{
    display: none;
}

.qo-searchBox .sui-search-box__text-input{
    padding: 18px 0px 18px 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid var(--indigo);
    border-radius: 6px;
    opacity: 1;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    height: 62px !important;
    margin-bottom: 0 !important;
    color: var(--indigo);
    font-family: "Grtsk-QuidelOrtho-V" !important;
}

.qo-search-icon{
    color: var(--indigo);
    font-size: 20px;
    opacity: 0.8;
    position: absolute;
    top: 22px;
    left: 20px;
}

.qo-clear-icon{
    color: var(--indigo);
    font-size: 20px;
    opacity: 0.8;
    position: absolute;
    top: 22px;
    right: 16px;
    cursor: pointer;
}


/* Facets filter  */

.filter-dsk{
    display: flex;
}

.filter-mob{
    display: none;
}

.sui-layout-sidebar-toggle{
    display: none;
}

.qo-side-heading-cnt{
    display: flex;
    justify-content: space-between;
}

.qo-side-heading{
    text-transform: uppercase;
    text-align: left;
    font-weight: 600;
    letter-spacing: 0px;
    color: var(--indigo);
    opacity: 1;
    font-size: 16px;
    line-height: 24px;
}

.qo-side-clear{
    font-size: 14px;
    line-height: 21px;
    color: var(--violet);
    cursor: pointer;
    text-transform: capitalize;
}

.qo-filter-heading{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    text-align: left;
    letter-spacing: 0px;
    color: var(--fontColor);
    /* margin-bottom: 20px; */
}

.qo-all-filter{
    display: flex;
    flex-direction: column;
}

.qo-filter-container{
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    /* display: -webkit-inline-box; */
}

.qo-filter-text{
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0px;
    color: var(--fontColor);
    opacity: 1;
}

.qo-filter-input{
    margin-right: 11px;
    min-width: 18px;
    width: 18px;
    height: 18px;
    position: relative;
}

.qo-hr-line{
    background: var(--lightSlate) 0% 0% no-repeat padding-box;
    opacity: 0.5;
    margin-bottom: 10px;
}

.qo-facet-more{
    margin-left: 30px;
    color: var(--indigo);
    cursor: pointer;
    text-transform: capitalize;
}

input[type='checkbox']:checked {
    background-color: #fff;
}

input[type='checkbox']:checked:after {
    content: '\2713';
    color:var(--indigo);
    position: absolute;
    left: 2px;
    top: -3px;
}

input[type='checkbox']{
    text-align: center;
    appearance:none;
    border-radius:2px;
    border: 1px solid var(--indigo);
    /* box-shadow: 5px 10px #888888; */
}

.qo-filter-accordian{
    padding: 0px !important;
}

.qo-filter-accordian-details{
    padding: 0px !important;
}

.MuiAccordion-root{
    box-shadow: none !important;
}

.toggle-filter-btn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 14px 0px 16px;
    gap: 8px;
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid var(--indigo);
    border-radius: 6px;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--indigo);
    text-transform: capitalize;
}



/* Page info  */

.qo-page-info{
    color: var(--indigo);
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}


/* Sorting  */

.sui-layout-main{
    padding-top: 20px !important;
}

.sui-sorting{
    width: 200px !important;
    cursor: pointer;
}

.sui-select__control{
    border: 1px solid var(--indigo) !important;
    background-color: #fff !important;
    border-radius: 6px;
    color: var(--violet);
    padding: 9px, 16px;
}

.sui-select{
    font-family: "Grtsk-QuidelOrtho-V" !important;
    
}

.sui-select__value-container--has-value{
    color: var(--indigo) !important;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 21px;
}

.sui-select__single-value{
    color: var(--indigo) !important;
}

/* Result card  */

.qo-result-cnt{
    display: flex;
    flex-wrap: wrap;
    row-gap: 35px;
    column-gap: 4%;
    margin-top: 44px;
}

.qo-result-card{
    padding: 18px;
    border-radius: 10px;
    border: 1px solid var(--lightSlate);
    width: 48%;
    flex-basis: 48%;
    cursor: pointer;

    color: unset !important;
    font-weight: unset !important;
    text-decoration: none !important;
}

.qo-result-card-title .qo-tooltip-div{
    color: var(--indigo);
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    /* letter-spacing: -0.035em; */
    text-transform: uppercase;

    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;

}

.qo-result-card-body{
    font-size: 20px;
    color: var(--fontColor);
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.03em;
    color: #414042;
    margin: 16px 0;
    height: 90px;

    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.qo-result-card-tag{

    display: flex;
    align-items: center;
    overflow: hidden;
}

.qo-result-card-tag .qo-tooltip-div{
    font-size: 16px;
    color: var(--violet);
    font-style: normal;
    line-height: 24px;
    
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
}


/* Pagination  */

.qo-pagination-cnt{
    margin-top: 90px;
}

.qo-pagination-cnt a{
    text-decoration: none !important;
}

.rc-pagination > li{
    font-size: 14px !important;
}

.rc-pagination-prev, .rc-pagination-next{
    border: 1px solid var(--lightSlate) !important;
}

.rc-pagination-prev a:after, .rc-pagination-next a:after{
    font-size: 25px;
    margin-top: -2px !important;
    color: var(--violet) !important;
}

.sui-paging .rc-pagination-item a{
    color: var(--fontColor) !important;
    font-weight: 400;
    text-decoration: none !important;
}

.sui-paging .rc-pagination-item-active a{
    color: var(--violet) !important;
    font-weight: 700;
}


/* FIlter Blocks  */

.qo-filter-block-cnt{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.qo-filter-block{
    display: flex;
    align-items: center;
    padding: 5px 12px;
    background: var(--lightGray);
    border-radius: 5px;
    font-size: 12px;
    line-height: 18px;
    color: var(--fontColor);
    margin: 10px 10px 5px 0;
}


/* Others  */
.qo-error-msg{
    text-align: center;
    margin-top: 50px;
    font-size: 20px;
    font-weight: 600;
}

.qo-no-result-msg{
    color: var(--indigo);
    font-family: "Grtsk-QuidelOrtho-V";
    margin: auto;
}

a{
    text-decoration: none;
}

.footer-hyperlink a:hover{
    color: #fff;
    text-decoration: underline;
}

/* Intermediate */

@media (min-width: 801px) and (max-width: 945px){
    .qo-result-card{
        flex-basis: 100%;
        width: 100%;
    }
}

/* Mobile  */
@media (max-width: 800px){


    /* Search Contsainer  */

    .qo-search-cnt{
        margin: 40px 0;
    }

    .qo-search-header-cnt{
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 32px;
    }

    .qo-search-header-txt{
        font-size: 31px;
        line-height: 40px;
        margin-right: 0;
        margin-bottom: 32px;
    }

    .qo-search-box-cnt{
        width: 100%;
    }

    .qo-searchBox .sui-search-box__text-input{
        padding: 12px 0px 12px 44px;
        border: 1px solid var(--indigo);
    }

    .qo-search-icon{
        top: 18px;
        left: 16px;
    }

    .qo-search-result-txt{
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }

    /* Facet filter  */

    .filter-dsk{
        display: none;
    }

    .filter-mob{
        display: flex;
    }

    .qo-side-heading-cnt-mob{
        justify-content: space-between;
        padding: 15px 0 10px 0;
    }

    .qo-facet-cnt {
        display: none;
    }
    
    .pop-filter{
        z-index: 99;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0 24px 30px 24px;
        background-color: #fcfcfc;
        overflow: auto;

        display: block;
        animation: fadein 0.2s ease-out;
    }

    .filter-result-cnt{
        display: flex;
        justify-content: center;
        margin: 60px 0 30px 0;
    }

    .filter-result-btn{
        font-family: "Grtsk-QuidelOrtho-V";
        font-size: 18px;
        padding: 8px 15px;
        background: var(--violet);
        color: #fff;
        border: 1px solid var(--violet);
        border-radius: 5px;
        text-transform: capitalize;
    }

    .sui-layout-body__inner{
        padding: 0 24px !important;
    }

    .sui-layout {
        width: auto;
    }

    .sui-layout-main {
        width: 100%;
        padding: 0;
    }
    
    .sui-layout-sidebar{
        padding: 0 24px !important;
        /* width: 100%;
        padding: 0; */
        display: unset;
        position: unset;
    }


    /* Page info  */

    .qo-page-info{
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .sui-layout-main-header__inner{
        flex-direction: column;
        align-items: flex-start;
    }

    /* Sorting  */

    .sui-sorting{
        width: 100% !important;
    }

    .sui-select__control{
        padding: 6px 4px;
    }


    /* Result Card  */
    .qo-result-card{
        flex-basis: 100%;
        width: 100%;
    }

    /* Pagination  */

    .qo-pagination-cnt{
        margin-top: 35px;
    }


}